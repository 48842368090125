<template>
  <b-modal
      id="external-category-link"
      title="Привязка категории"
      ok-title="Сохранить"
      @ok="link"
      cancel-title="Отмена"
      cancel-variant="outline-secondary"
  >
    <div class="row mb-3" v-if="category">
      <div class="col-12">Выбранная категория Brandstock: {{ category.name }}</div>
    </div>
    <div class="row mb-2">
      <div class="col-12">
        <label class="title">Категория Маркетплейса</label>
        <treeselect
            v-model="external_category"
            :options="categories"
            :searchable="true"
            :normalizer="normalizer"
            placeholder="Выберите категорию маркетплейса"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import Treeselect from '@riophae/vue-treeselect';
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  name: 'ExternalCategoryLinkModal',
  components: {
    BModal, Treeselect,
  },
  props: ['category', 'externalServiceId'],
  data() {
    return {
      external_category: null,
      categories: [],
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
          children: node.all_children,
        };
      },
    };
  },
  async mounted() {
    await this.getExternalCategories();
  },
  methods: {
    async getExternalCategories() {
      this.categories = (await this.$api.external.categories.select(this.externalServiceId)).data;
    },
    async link() {
      await this.$api.external.categories.link(this.external_category, { category_id: this.category.id });
      this.$emit('update');
    },
  },
};
</script>

<style scoped>

</style>
