import { render, staticRenderFns } from "./ExternalServiceCategory.vue?vue&type=template&id=060a5e06&scoped=true&"
import script from "./ExternalServiceCategory.vue?vue&type=script&lang=js&"
export * from "./ExternalServiceCategory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "060a5e06",
  null
  
)

export default component.exports