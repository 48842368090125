<template>
  <div class="col-12">
    <div class="row mt-3 mb-2">
      <div class="col-12 col-md-6">
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
              v-model="form.categories.searchQuery"
              @change="getCategories"
              class="d-inline-block mr-1"
              placeholder="Поиск..."
          />
        </div>
      </div>
    </div>
    <div class="mx-2 mb-2">
      <div class="row">
        <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">
            Показано с {{ categories.from }} по {{ categories.to }} из {{ categories.total }} записей
          </span>
        </div>
        <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
              v-model="form.categories.currentPage"
              :total-rows="categories.total"
              :per-page="categories.per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
    <div class="position-relative table-responsive">
      <table class="table b-table">
        <thead>
        <tr>
          <th>#</th>
          <th>Название</th>
          <th>Количество товаров в наличии</th>
          <th>Путь / Url</th>
          <th colspan="2">Действия</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="category in categories.data" :key="category.id">
          <td>{{ category.id }}</td>
          <td>
            <router-link :to="{ name: `${prefix}-categories`, params: { id: category.id } }">
              {{ category.name }}
            </router-link>
          </td>
          <td>{{ category.products_count }}</td>
          <td>
            <router-link :to="{ name: `${prefix}-categories`, params: { id: category.parent_id } }">
              {{ category.full_parent_path }}
            </router-link>
            <br>
            {{ category.url }}
          </td>
          <td>
            <div class="btn btn-info" @click="openLinkModal(category)">Привязать</div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="mx-2 mb-2">
      <div class="row">
        <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">
            Показано с {{ categories.from }} по {{ categories.to }} из {{ categories.total }} записей
          </span>
        </div>
        <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
              v-model="form.categories.currentPage"
              :total-rows="categories.total"
              :per-page="categories.per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
    <ExternalCategoryLinkModal
        :category="selectedCategory"
        :external-service-id="externalServiceId"
        @update="getCategories"
    />
  </div>
</template>

<script>
import {
  BFormInput, BPagination,
} from 'bootstrap-vue';
import ExternalCategoryLinkModal from '@/views/automation/ExternalCategoryLinkModal.vue';

export default {
  name: 'ExternalServiceCategory',
  props: ['externalServiceId', 'prefix'],
  components: {
    BFormInput,
    BPagination,
    ExternalCategoryLinkModal,
  },
  data() {
    return {
      form: {
        categories: {
          searchQuery: null,
          currentPage: 1,
        },
      },
      categories: {
        current_page: 1,
        data: [],
        from: null,
        to: null,
        total: null,
        per_page: 10,
      },
      selectedCategory: null,
    };
  },
  watch: {
    'form.categories.currentPage': {
      async handler(newValue) {
        this.categories.current_page = newValue;
        await this.getCategories();
      },
    },
    externalServiceId: {
      async handler() {
        await this.getCategories();
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.getCategories();
  },
  methods: {
    async getCategories() {
      this.categories = (await this.$api.categories.notLinked(this.externalServiceId, {
        ...(this.categories.current_page > 1 && { page: this.categories.current_page }),
        limit: 25,
        ...(this.form.categories.searchQuery && { search: this.form.categories.searchQuery }),
      })).data;
    },
    openLinkModal(category) {
      this.selectedCategory = category;
      this.$bvModal.show('external-category-link');
    },
  },
};
</script>

<style scoped>
</style>
